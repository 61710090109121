<template>
  <div class="flex justify-between min-w-[343px]" v-if="props.type == TOAST_TYPE.LOADING">
    <div class="font-Abu text-label-big text-white opacity-[0.87]">{{ content }}</div>
    <div>
      <UiCountdownIcon
        :isShowClose="props.isShowClose"
        :duration=props.duration
        :size=props.size
        @close="handleClose"
      >
      </UiCountdownIcon>
    </div>
  </div>
  <div v-else class="w-full h-full flex items-center min-w-[343px] bg-transparent gap-x-4">
    <SvgsWarn width="24" height="24" :class="className"></SvgsWarn>
    <div 
      :class="className"
      class="text-label-big font-Abu"
    >{{content}}</div>
  </div>
</template>
<script setup>
import { TOAST_TYPE } from '@/constants'
const props = defineProps({
    content:{
        type:String,
    },
    isLoading: {
      type: Boolean,
    },
    isShowClose: {
      type: Boolean,
    },
    duration: {
      type: Number,
      default: 4
    },
    size: {
      type: Number,
      default: 24
    },
    type: {
      type:String,
      default:'normal'
    }
});

const emit = defineEmits(["close"]);
const className = computed(() => ([
  {'text-brand-red-error':props.type == TOAST_TYPE.ERROR},
  {'text-white opacity-[0.87]':props.type == TOAST_TYPE.NORMAL},
]))
const handleClose = () => {
  emit('close');
}

</script>