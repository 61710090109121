import Toast from '@/components/ui/toast/ToastForMessage.vue';
import { TOAST_TYPE } from '@/constants';
export default () => {
    const openToast = (params) => {
        const ch = window.innerHeight || document.documentElement.clientHeight;
        const { type=TOAST_TYPE.NORMAL, content, duration=4,isShowClose= true } = params
        const className = `${TOAST_TYPE[type.toUpperCase()]}-message`;
        return new Promise((resolve) => {
            message.config({ top: `${ch - 90}px`})
            message.open({
                key: Date.now(),
                class: className,
                content: () => 
                h(
                    Toast,
                    {
                        type,
                        content,
                        duration,
                        isShowClose,
                        onClose: () => {
                            message.destroy()
                            resolve()
                        },
                    },
                ),
                onClose: () => {
                    resolve()
                },
                duration,
                zIndex:2000
            });
        })
    }
    const closeToast = () => {
        message.destroy()
    }
    return {
        openToast,
        closeToast
    }
}