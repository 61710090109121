<template>
  <div class="relative">
    <svg x="0px" y="0px" :width="props.size" :height="props.size" :viewBox="viewBox">
      <circle ref="circleIcon"
        class="path" 
        stroke="white"
        fill="none" 
        stroke-width="3" 
        :stroke-dasharray="perimeter"
        :cx="(props.size + 4) / 2"
        :cy="(props.size + 4) / 2"
        :r="props.size / 2"
      />
    </svg>
  <IconClose
    v-if="props.isShowClose"
    class="center-icon cursor-pointer"
    name="IconClose"
    width="14"
    height="14"
    @click="emit('close')"
  ></IconClose>
  </div>
</template>
<script setup>
const props = defineProps({
    duration: {
      type: Number,
      default: 4
    },
    size: {
      type: Number,
      default: 24,
    },
    isShowClose: {
      type: Boolean,
      default: false,
    }
});
const emit = defineEmits(["close"]);

const circleIcon = ref();
const viewBox = computed( () => {
  return `0 0 ${props.size + 4} ${props.size + 4}`;
})
const perimeter = computed( () => {
  return 2 * Math.PI * (props.size / 2);
})
onMounted(() => {
  circleIcon.value.style.setProperty('--toast-duration', `${props.duration + 0.4}s`);
  circleIcon.value.style.setProperty('--toast-stroke-dashoffset', `${2 * perimeter.value}`);
  circleIcon.value.style.setProperty('--toast-stroke-dashoffset-end', `${perimeter.value}`);
});
</script>
<style scoped>
.center-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
svg {
  transform: rotate(-90deg);
}

.path {
  stroke-dashoffset: var(--toast-stroke-dashoffset);
  animation: run var(--toast-duration) linear;
}

@keyframes run {
  to {
     stroke-dashoffset: var(--toast-stroke-dashoffset-end);
  }
}
</style>